<template>
  <div class="pickPartnerProductContainer">
    <el-dialog :close-on-click-modal="false" :visible="value" title="关联券ID" width="60%" :before-close="beforeClose">
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <el-input class="searchInput" placeholder="请输入券名称" v-model="search.name" clearable> </el-input>
        </el-col>
        <el-col :span="8">
          <el-input class="searchInput" placeholder="请输入券id" v-model="search.id" clearable> </el-input>
        </el-col>
        <el-col :span="8"> <el-button type="primary" @click="getList()"> 搜索</el-button></el-col>
      </el-row>
      <el-table :data="list" tooltip-effect="dark" style="width: 100%">
        <el-table-column align="center" label="id" prop="id"> </el-table-column>
        <el-table-column align="center" label="劵ID" prop="couponTemplateId"> </el-table-column>
        <el-table-column align="center" prop="title" label="劵名称"> </el-table-column>

        <el-table-column align="center" label="库存" prop="stock"> </el-table-column>

        <el-table-column align="center" label=" 价格" prop="faceValue"> </el-table-column>
        <el-table-column align="center" label=" 时间" width="300px">
          <template slot-scope="scope">
            开始： {{ scope.row.startTime | formatTime }}
            <br />
            结束： {{ scope.row.endTime | formatTime }}
          </template>
        </el-table-column>

        <el-table-column align="center" label=" 操作">
          <template slot-scope="scope">
            <el-button type="primary" v-if="selectId === scope.row.id">已选</el-button>
            <el-button type="success" @click="handleSelect(scope.row)" v-else>未选</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="footer">
        <el-pagination
          :current-page="search.page"
          :page-sizes="pageSizes"
          :page-size="search.size"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>

      <span slot="footer">
        <el-button round type="primary" @click="confirmRelate()">确定</el-button>

        <bm-tip>一个商品只能关联一个券id</bm-tip>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { pagination } from '@/mixin/pagination'
export default {
  mixins: [pagination],
  name: 'comPickCoupon',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      search: {
        id: '',
        name: ''
      },
      list: [],
      selectId: '',
      select: {}
    }
  },
  computed: {},
  created() {
    console.log(this.value)
  },
  methods: {
    ...mapMutations('product/partner', ['updateCache']),
    handleSelect(row) {
      this.selectId = row.id
      this.select = { ...row }
    },
    handleAdd() {
      this.$emit('input', true)
      this.getList()
    },
    getList() {
      this.$http
        .get('/boom-center-cash-service/sysAdmin/hualala/coupon-templates', {
          params: {
            page: this.currentPage,
            size: this.pageSize,
            couponTemplateId: this.search.id,
            queryStr: this.search.name
          }
        })
        .then(res => {
          this.list = res.list
          this.total = res.total
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    beforeClose() {
      this.$emit('input', false)
    },
    confirmRelate() {
      if (!this.selectId) return
      this.updateCache({ key: 'switchExternal', value: 1 })
      this.updateCache({ key: 'externalTableId', value: this.selectId })
      this.updateCache({ key: 'couponId', value: this.select.couponTemplateId })
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="less" scoped>
.query {
  margin: 20px;
}
.footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
