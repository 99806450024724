<route>
{
  "meta": {
    "noTransition": true
  }
}
</route>

<template>
  <com-layout ref="editLayout" class="page">
    <el-card v-loading="loading">
      <el-form :rules="cacheFormRule" :model="cache" ref="cacheFormBasic" label-width="10em">
        <el-form-item label="所属商家" required>
          <div class="business_wrapper">
            <div class="businessInfo" @click="openBusinessPicker">
              <template v-if="businessInfo.logo">
                <div class="logo" v-bg.cover="businessInfo.logo"></div>
              </template>
              <div class="title">{{ businessInfo.name }}</div>
            </div>
            <div class="oaissue_id" v-if="!this.$route.query.copy">
              <div class="id" v-if="showOaIssueId">工单编号: {{ cache.oaIssueId }}</div>
              <div v-else>无关联工单</div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="关联门店">
          <div>
            <ul class="store_list">
              <li>
                <el-button type="text" @click="handleSetArea(-1)" size="middle">全部</el-button>
              </li>
              <li v-for="item in areaIdList" :key="item.areaId">
                <el-button type="text" @click="handleSetArea(item.areaId)" size="middle">{{ item.name }}</el-button>
              </li>
            </ul>
          </div>
          <div class="storeList">
            <div class="storeItem" v-for="(i, n) in filterStoreList" :key="i.id">
              <el-checkbox
                :value="i.isEnable"
                :disabled="i.disabled && !allowUpdateAll"
                @input="toggleStore(n)"
              ></el-checkbox>
              <div class="name">{{ i.storeTitle }}</div>
              <div class="name">{{ i.areaId | formatArea }}</div>
              <template v-if="i.isEnable">
                <el-tag v-if="i.bindedSubMerchant === 1" style="margin-right: 8px">有商户号</el-tag>
                <el-tag
                  v-else-if="cache.businessModel !== 1 && businessInfo.businessModel !== 1"
                  type="danger"
                  style="margin-right: 8px"
                  >无商户号</el-tag
                >
                <el-select
                  v-if="saleType != 5"
                  :value="i.bookingRuleId"
                  @input="updateStoreBookingRule({ storeIndex: n, value: $event })"
                >
                  <el-option
                    v-for="rule in bookingRuleList"
                    :key="rule.id"
                    :label="rule.ruleName"
                    :value="rule.id"
                  ></el-option>
                </el-select>
              </template>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="商品名称" prop="productName">
          <el-input
            :value="cache.productName"
            @input="updateCache({ key: 'productName', value: $event })"
            maxlength="60"
            show-word-limit
            placeholder="请输入商品名称"
            :autosize="{ minRows: 2, maxRows: 4 }"
            type="textarea"
            class="middleInput"
          ></el-input>

          <bm-tip>商品名称不能超过60个字，请尽量清晰描述，方便用户查阅</bm-tip>
          <!-- <el-button
            v-if="($route.params.id === '0' || $route.query.copy) && saleType === 1"
            type="success"
            @click="handleAddCoupon"
            >关联券ID</el-button
          > -->
          <div v-if="Boolean(cache.switchExternal)">
            已关联券id：{{ cache.couponId }}
            <span
              v-if="$route.params.id === '0' || $route.query.copy"
              style="color: blue; cursor: pointer; user-select: none"
              @click="handleCancelRelevance"
              >取消关联</span
            >
          </div>
        </el-form-item>
        <el-form-item label="商品描述" prop="productDescription">
          <el-input
            :value="cache.productDescription"
            @input="updateCache({ key: 'productDescription', value: $event })"
            maxlength="20"
            show-word-limit
            placeholder="请输入商品描述"
            class="middleInput"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品搜索关键字" prop="productKeyword">
          <el-input
            :value="cache.productKeyword"
            @input="updateCache({ key: 'productKeyword', value: $event })"
            maxlength="60"
            show-word-limit
            placeholder="请输入商品搜索关键字"
            class="middleInput"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品单位" prop="productUnit">
          <el-select
            :value="cache.productUnit"
            @input="updateCache({ key: 'productUnit', value: $event })"
            filterable
            allow-create
            default-first-option
            placeholder="请输入或选择商品单位"
          >
            <el-option v-for="(i, n) in ['个', '份', '盒', '张']" :key="n" :label="i" :value="i"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="商品一级分类" required>
          <el-select
            :value="productCategoryInfo.main"
            @input="updateProductCategoryInfo({ key: 'main', value: $event })"
          >
            <el-option
              v-for="(i, n) in productMainCategoryList"
              :key="n"
              :label="i.categoryName"
              :value="i.id"
              placeholder="请选择一级分类"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="productCategoryInfo.main" label="商品二级分类" required>
          <el-cascader
            :value="productCategoryInfo.sub"
            @input="updateProductCategoryInfo({ key: 'sub', value: $event })"
            :options="productSubCategoryList"
            :props="{
              multiple: true,
              value: 'id',
              label: 'categoryName',
              children: 'childList'
            }"
            placeholder="请选择二级/三级分类"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="商品相册" required>
          <bm-upload
            type="commodity"
            :reduceResolution="true"
            :value="productImage"
            :format="imageAccept"
            @input="updateProductImage"
            :limit="1000"
          ></bm-upload>
          <div style="font-size: 12px; color: #999">建议上传比例1:1 ，尺寸在750px ~ 1200px 之间的图片</div>
        </el-form-item>
        <el-form-item label="商品款式" required>
          <el-form>
            <el-form-item>
              <el-button type="success" @click="handleAddSku">添加商品款式</el-button>
            </el-form-item>
          </el-form>
        </el-form-item>
        <draggable v-model="cache.skuList">
          <el-form-item v-for="(i, n) in cache.skuList" :key="n" class="drag_style">
            <div class="skuCard">
              <div class="skuImage" v-bg.contain="i.skuImageUrl"></div>
              <div class="info">
                <div class="name">{{ i.skuName }}</div>
                <div class="infoContainer">
                  <div class="price">
                    <div class="currentPrice">售价：￥{{ i.salePrice | formatPrice }}</div>
                    <div class="originalPrice assist">原价：￥ {{ i.originalPrice | formatPrice }}</div>
                  </div>
                  <div class="stock">
                    <div>签约库存：{{ i.totalStock }}</div>
                    <div>库存预警：{{ i.lowStockRation }} %</div>
                  </div>
                </div>
              </div>
              <div class="action">
                <el-button @click="handleEditSku(i, n)" type="text">编 辑</el-button>
                <el-button v-if="!i.id" @click="deleteSku(n)" type="text">删 除</el-button>
              </div>
            </div>
          </el-form-item>
        </draggable>

        <el-form-item label="显示分段库存开关">
          <el-switch v-model="cache.switchSectionStock" :inactive-value="0" :active-value="1"></el-switch>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleNext" type="primary">下一步</el-button>
          <el-button v-if="!isAdd" @click="handleSubmit" type="success">提交</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <expressSku ref="expressSku" v-model="isShowSkuContainer" :is-add="isAdd"></expressSku>
    <com-editSku ref="pickSkuForm" v-model="isShowExpressSku" :is-add="isAdd"></com-editSku>
    <seckillSku ref="seckillSku" v-model="isShowSeckillSku" :is-add="isAdd"></seckillSku>
    <com-pickPartnerProduct
      ref="pickPartnerProduct"
      v-model="isShowPickPartnerProductContainer"
    ></com-pickPartnerProduct>
    <com-pickCoupon v-model="isShowCouponContainer" ref="pickCoupon"></com-pickCoupon>
  </com-layout>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import editMixin from './__com__/editMixin'
import comPickPartnerProduct from './__com__/pickPartnerProduct'
import comPickCoupon from './__com__/pickCoupon'
// comPickCoupon
import comEditSku from './__com__/editSku'
import draggable from 'vuedraggable'
import seckillSku from './__com__/seckillSku.vue'
import { formatArea } from '@/util/filter'
import expressSku from './__com__/expressSku.vue'
export default {
  data: () => ({
    foo: 'bar',
    isShowSkuContainer: false,
    isShowExpressSku: false,
    isShowSeckillSku: false,
    isShowCouponContainer: false,
    isShowPickPartnerProductContainer: false,
    imageAccept: ['png', 'jpg', 'jpeg', 'bmp'],
    pickBookingRuleData: {
      isShow: false,
      storeId: '',
      bookingRuleId: ''
    },
    filterAreaId: -1
  }),
  mixins: [editMixin],
  components: { comPickPartnerProduct, comEditSku, draggable, comPickCoupon, seckillSku, expressSku },
  computed: {
    showOaIssueId() {
      return isNaN(this.cache.oaIssueId)
    },
    ...mapGetters('product/partner', [
      'cache',
      'businessInfo',
      'storeList',
      'productImage',
      'productCategoryInfo',
      'saleType',
      'allowUpdateAll'
    ]),
    ...mapGetters({
      productMainCategoryList: 'product/basic/productMainCategoryList',
      productCategoryList: 'product/basic/productCategoryList',
      bookingRuleList: 'product/basic/bookingRuleList'
    }),
    productSubCategoryList() {
      if (this.productCategoryInfo.main && this.productCategoryList.length) {
        const c = this.productCategoryList.find(i => i.id === this.productCategoryInfo.main)
        if (c) {
          return c.childList
        }
      }
    },
    isCopy() {
      return Boolean(this.$route.query.copy)
    },
    areaIdList() {
      let temp = [...new Set(this.storeList.map(item => item.areaId))]
      return temp.map(item => ({ areaId: item, name: formatArea(item) }))
    },
    filterStoreList() {
      return this.storeList.filter(item => {
        return item.areaId === this.filterAreaId || this.filterAreaId === -1
      })
    }
  },
  methods: {
    ...mapMutations('product/partner', [
      'toggleStore',
      'updateStoreBookingRule',
      'updateProductCategoryInfo',
      'updateProductImage',
      'deleteSku'
    ]),
    ...mapActions({
      getProductCategory: 'product/basic/getProductCategory',
      getBookingRuleList: 'product/basic/getBookingRuleList',
      mallShareRatio: 'product/basic/mallShareRatio'
    }),
    handleSetArea(areaId) {
      this.filterAreaId = areaId
    },
    handleNext() {
      this.$refs.cacheFormBasic.validate(valid => {
        if (valid) {
          let query = {}
          if (this.$route.query.copy) {
            query.copy = 1
          }
          this.$router.push({
            path: `/product/partnerProduct/${this.$route.params.id}/detail?id=${this.$route.query.id || ''}`,
            query
          })
        } else {
          this.$message.error('表单信息不完整')
          return false
        }
      })
    },
    handleAddCoupon() {
      this.$refs.pickCoupon.handleAdd()
    },
    handleAddSku() {
      switch (this.saleType) {
        case 1:
        case 3:
          this.$refs.pickSkuForm.handleAdd()
          break
        case 2:
          this.$refs.pickPartnerProduct.handleAdd()
          break
        case 4:
          this.$refs.seckillSku.handleAdd()
          break
        case 5:
          this.$refs.expressSku.handleAdd()
          break
        default:
          this.$refs.pickSkuForm.handleAdd()
          break
      }
    },
    handleEditSku(val, index) {
      this.$store.commit('product/partner/update', {
        key: 'currentSkuIndex',
        value: index
      })

      switch (this.saleType) {
        case 1:
        case 3:
          this.$refs.pickSkuForm.handleEdit(val)
          break
        case 2:
          this.$refs.pickPartnerProduct.handleEdit(val)
          break
        case 4:
          this.$refs.seckillSku.handleEdit(val)
          break
        case 5:
          this.$refs.expressSku.handleEdit(val)
          break
        default:
          this.$refs.pickSkuForm.handleEdit(val)
          break
      }
    },
    openBusinessPicker() {
      if (this.isAdd || this.isCopy || this.allowUpdateAll) {
        this.$refs.editLayout.isShowBusinessPicker = true
      }
    },
    //取消关联外部券
    handleCancelRelevance() {
      this.updateCache({ key: 'switchExternal', value: 0 })
      this.updateCache({ key: 'externalTableId', value: '' })
      this.updateCache({ key: 'couponId', value: '' })
      this.$forceUpdate()
    }
  },
  async mounted() {
    if (
      this.$route.params.id == 0 &&
      (this.saleType == 1 || this.saleType == 2 || this.saleType == 3 || this.saleType == 5)
    ) {
      this.updateCache({ key: 'buyLimit', value: 10 })
    }
    this.loading = true
    Promise.all([this.getData(this.$route.params.id), this.getProductCategory()]).finally(() => {
      this.mallShareRatio()
      if (this.cache.externalTableId) {
        this.$http
          .get('/boom-center-cash-service/sysAdmin/hualala/coupon-templates/' + this.cache.externalTableId)
          .then(res => {
            this.updateCache({ key: 'couponId', value: res.couponTemplateId })
            this.$forceUpdate()
          })
      }
      this.loading = false
    })
  }
}
</script>

<style lang="less" scoped>
.drag_style {
  display: inline-block;
  cursor: move;
  margin-right: calc(100% - 650px);
}
.page {
  width: 100%;
}
.business_wrapper {
  display: flex;
  align-items: center;
  .businessInfo {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    cursor: pointer;
    .logo {
      .square(50px);
    }
    .title {
      margin: 0 10px;
      font-size: 18px;
    }
  }
}

.store_list {
  display: flex;
  list-style: none;
  li {
    font-size: 14px;
    margin: 0 10px;
  }
}

.storeList {
  .radius;
  background: var(--background);
  width: 400px;
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
  .storeItem {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 5px 0;
    .name {
      margin: 0 10px;
      width: 200px;
    }
  }
}

.skuCard {
  .border;
  .radius;
  background: var(--background);
  display: flex;
  align-items: center;
  width: 500px;
  padding: 10px;
  .skuImage {
    .square(100px);
  }
  .info {
    flex-grow: 1;
    margin: 0 10px;
    .infoContainer {
      display: flex;
      flex-direction: column;
      .price,
      .stock {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>
