<template>
  <div class="pickPartnerProductContainer">
    <el-dialog
      :visible="value"
      :close-on-click-modal="false"
      :before-close="beforeClose"
      title="添加商品款式"
      width="800px"
    >
      <el-form label-width="10em" class="editSkuContainer" :model="editSkuData" :rules="skuFormRule" ref="editSkuData">
        <el-form-item label="款式图片" prop="skuImageUrl">
          <bm-upload type="commodity" v-model="editSkuData.skuImageUrl"></bm-upload>
        </el-form-item>
        <el-form-item label="款式名称" prop="skuName">
          <el-input v-model.trim="editSkuData.skuName"></el-input>
        </el-form-item>
        <el-form-item label="售卖价格" prop="salePrice">
          <el-input-number
            :disabled="!allowUpdateAll && editSkuData.id !== ''"
            v-model="editSkuData.salePrice"
            controls-position="right"
            :min="0"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="原价" prop="originalPrice">
          <el-input-number v-model="editSkuData.originalPrice" controls-position="right" :min="0">
            <template slot="append">%</template></el-input-number
          >
        </el-form-item>

        <el-form-item label="签约总库存" prop="totalStock">
          <el-input-number v-model="editSkuData.totalStock" controls-position="right" :min="0"></el-input-number>
        </el-form-item>

        <el-form-item label="库存预警比例" prop="lowStockRation">
          <el-input :min="0" oninput="value=value.replace(/[^0-9.]/g,'')" v-model.number="editSkuData.lowStockRation">
            <template slot="append">%</template></el-input
          >
        </el-form-item>

        <el-form-item label="基础销量数" prop="baseVirtualSaleCount">
          <el-input-number
            v-model="editSkuData.baseVirtualSaleCount"
            controls-position="right"
            :min="0"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="款式限购" prop="buyLimit">
          <el-input-number v-model="editSkuData.buyLimit" controls-position="right" :min="0"></el-input-number>
        </el-form-item>

        <el-form-item label="退款比例开关">
          <el-switch v-model="editSkuData.switchRefundRatio" :inactive-value="0" :active-value="1"></el-switch>
        </el-form-item>
        <el-form-item label="是否隐藏sku">
          <el-radio-group v-model="editSkuData.hideStatus">
            <el-radio :disabled="disableHideSku" :label="0">显示</el-radio>
            <el-radio :disabled="disableHideSku" :label="1">隐藏</el-radio>
          </el-radio-group>

          <bm-tip>第一个Sku不能隐藏，只能显示</bm-tip>
        </el-form-item>
        <el-form-item label="退款比例" v-if="editSkuData.switchRefundRatio === 1">
          <el-input :min="0" oninput="value=value.replace(/[^0-9.]/g,'')" v-model="editSkuData.refundRatio">
            <template slot="append">%</template></el-input
          >
        </el-form-item>

        <!-- <el-form-item label="是否参与分销">
          <el-switch v-model="editSkuData.switchDistribution"  disabled :inactive-value="0" :active-value="1"></el-switch>
          <bm-tip>请注意其他SKU的分销设置要一致，建议参与分销SKU与不参与分销SKU分开上架</bm-tip>
        </el-form-item> -->

        <!-- <el-form-item label="附带信息">
          <div v-for="(item, index) in editSkuData.formItemList" :key="'formItem' + index">
            <el-input
              :disabled="!allowUpdateAll && editSkuData.id !== ''"
              v-model="item.label"
              style="margin-right: 10px"
              placeholder="信息名称"
            ></el-input>
            <el-select :disabled="!allowUpdateAll && editSkuData.id !== ''" v-model="item.code" placeholder="请选择">
              <el-option v-for="i in options" :key="i.value" :label="i.label" :value="i.value"> </el-option>
            </el-select>

            <el-checkbox v-model="item.required" style="margin: 10px">必填</el-checkbox>
            <el-button
              class="deleteBtn"
              type="danger"
              :disabled="!allowUpdateAll && editSkuData.id !== ''"
              @click="handleDeleteFormItem(index)"
              icon="el-icon-delete"
            ></el-button>
          </div>

          <el-button
            style="margin-top: 10px"
            :disabled="!allowUpdateAll && editSkuData.id !== ''"
            @click="handleAddFormItem"
            type="success"
            >新增下单信息</el-button
          >
        </el-form-item> -->

        <el-form-item label="规格列表">
          <div v-for="(item, index) in editSkuData.options" :key="'spec' + index">
            <el-input
              :disabled="!allowUpdateAll && editSkuData.id !== ''"
              v-model="item.name"
              style="margin-right: 10px"
              placeholder="name名称"
            ></el-input>

            <el-button
              class="deleteBtn"
              type="danger"
              :disabled="!allowUpdateAll && editSkuData.id !== ''"
              @click="handleDeleteOptionsItem(index)"
              icon="el-icon-delete"
            ></el-button>
          </div>

          <el-button
            :disabled="!allowUpdateAll && editSkuData.id !== ''"
            style="margin-top: 10px"
            @click="handleAddOptionsItem"
            type="success"
            >新增规格</el-button
          >
        </el-form-item>
        <el-form-item label="每份需填" v-if="editSkuData.formItemList.length !== 0">
          <el-input-number
            :disabled="!allowUpdateAll && editSkuData.id !== ''"
            v-model="editSkuData.extraInfoNum"
            controls-position="right"
            :min="0"
            :max="99"
          ></el-input-number>

          <bm-tip>每购买一份，需填写信息的份数</bm-tip>
        </el-form-item>
        <el-form-item label="结算方式">
          <el-radio-group v-model="editSkuData.settlementType">
            <el-radio :label="1" :disabled="!allowUpdateAll && editSkuData.id !== ''">按比例结算</el-radio>
            <el-radio :label="2" :disabled="!allowUpdateAll && editSkuData.id !== ''">固定金额</el-radio>
          </el-radio-group>
          <div class="skuSettlement" v-for="(i, n) in editSkuData.settlementList" :key="n">
            <el-form>
              <el-form-item>
                <div style="width: 50%; display: inline-block; vertical-align: top">
                  <span style="margin: 10px">第{{ n + 1 }}段库存</span>
                  <el-input
                    class="skuSettlementInput"
                    style="width: 150px"
                    v-model="i.sectionStock"
                    type="number"
                    @blur="computeMax(n)"
                  ></el-input>

                  <div v-if="i.id !== ''" style="margin: 10px; color: red">剩余库存量 {{ i.currentSectionStock }}</div>
                </div>
                <div style="width: 50%; display: inline-block">
                  <span style="margin: 10px">平台分成</span>
                  <template v-if="editSkuData.settlementType === 1">
                    <el-input
                      type="number"
                      style="width: 150px"
                      :disabled="!allowUpdateAll && i.id !== ''"
                      v-model="i.platformShareRatio"
                      @blur="handleShareLimit(n)"
                      :min="0"
                      :max="100"
                    >
                    </el-input>

                    <span style="margin-left: 5px">%</span>
                  </template>
                  <template v-else-if="editSkuData.settlementType === 2">
                    <el-input
                      type="number"
                      style="width: 150px"
                      :disabled="!allowUpdateAll && i.id !== ''"
                      v-model="i.platformShareMoney"
                      @blur="handleShareLimit(n)"
                      :max="editSkuData.salePrice"
                      :min="0"
                    >
                    </el-input>
                  </template>

                  <div>
                    <span style="margin: 10px">商家分成 </span>
                    <template v-if="editSkuData.settlementType === 1">
                      <span>{{ 100 - i.platformShareRatio }}</span>
                      <span>%</span>

                      <div v-if="i.platformShareRatio == 0" style="color: red; font-size: 20px; margin-left: 10px">
                        请确定是商家百分百全收
                      </div>

                      <div v-if="i.platformShareRatio >= 30" style="color: red; font-size: 20px; margin-left: 10px">
                        请确定商品业务类型，判断是否需要填写特殊分销金
                      </div>
                    </template>
                    <template v-else-if="editSkuData.settlementType === 2">
                      <span>{{ (skuPrice - i.platformShareMoney).toFixed(2) }}</span>
                      <span>元</span>
                      <div v-if="i.platformShareMoney == 0" style="color: red; font-size: 20px; margin-left: 10px">
                        请确定是商家百分百全收
                      </div>
                      <div
                        v-if="i.platformShareMoney / editSkuData.salePrice >= 0.3"
                        style="color: red; font-size: 20px; margin-left: 10px"
                      >
                        请确定商品业务类型，判断是否需要填写特殊分销金
                      </div>
                    </template>
                  </div>
                </div>
              </el-form-item>
              <el-form-item v-if="editSkuData.switchDistribution === 1">
                <span style="margin: 10px">特殊奖励金开关 </span>
                <el-switch v-model="i.switchSpecialDistribution" :inactive-value="0" :active-value="1"></el-switch>
                <span
                  v-if="i.switchSpecialDistribution === 1 && editSkuData.switchDistribution === 1"
                  style="margin: 10px"
                  >特殊分销奖励金
                </span>

                <el-input
                  @change="handleSpecialDistributionRewardAmount(n)"
                  v-if="i.switchSpecialDistribution === 1 && editSkuData.switchDistribution === 1"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  v-model="i.specialDistributionRewardAmount"
                >
                  <template slot="append">元</template></el-input
                >
              </el-form-item>
            </el-form>
            <template v-if="n !== 0">
              <el-button
                class="deleteBtn"
                type="danger"
                v-if="showDelete(n, i)"
                @click="handleDeleteSettlement(n)"
                icon="el-icon-delete"
              ></el-button>
            </template>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button v-if="canAddSettlement" @click="handleAddSettlement" type="success">增加分成比例</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="validateSku" :disabled="isReadySubmitEditSku" type="primary">完成</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog :visible.sync="showTip" :close-on-click-modal="false" title="提醒" center width="700px">
      <h3 style="text-align: center">请确定商家百分百全收</h3>
      <span slot="footer" class="dialog-footer">
        <el-button round @click="showTip = false">取 消</el-button>

        <el-button type="primary" round @click="submitEditSku">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    options: [
      {
        value: 'idCard',
        label: '身份证号码'
      },
      {
        value: 'phone',
        label: '手机号码'
      },
      {
        value: 'time',
        label: '时间格式'
      },
      {
        value: 'date',
        label: '日期格式'
      },
      {
        value: 'text',
        label: '文本格式'
      }
    ],
    editSkuData: {
      productSkuId: '',
      skuImageUrl: '',
      skuName: '',
      totalStock: 0,
      salePrice: 0,
      extraInfoNum: 0,
      buyLimit: 0,
      options: [],
      formItemList: [],
      originalPrice: 0,
      settlementType: 1,
      lowStockRation: 0,
      id: '',
      switchRefundRatio: 0,
      refundRatio: 0,
      baseVirtualSaleCount: 0,
      lowStockRation: 0,
      settlementList: [
        {
          sectionStock: 0,
          id: '',
          platformShareMoney: 9999999999,
          platformShareRatio: 100,
          settlementType: 1,
          currentSectionStock: 0,
          specialDistributionRewardAmount: 0,
          switchSpecialDistribution: 0
        }
      ]
    },
    isShowSkuContainer: false,
    showTip: false,
    isEdit: false
  }),
  computed: {
    ...mapGetters('product/partner', [
      'businessInfo',
      'hasSubMerchant',
      'allowUpdateAll',
      'saleType',
      'skuFormRule',
      'commodityData',
      'cache'
    ]),
    ...mapGetters({
      mallShareRatio: 'product/basic/mallShareRatio'
    }),
    isReadySubmitEditSku() {
      return false
    },
    skuPrice() {
      return this.editSkuData.salePrice
    },
    canAddSettlement() {
      const l = this.editSkuData.settlementList.length
      if (
        this.editSkuData.settlementList.length &&
        this.editSkuData.settlementList[l - 1].sectionEnd >= this.editSkuData.totalStock
      ) {
        return false
      } else {
        return true
      }
    }
  },
  watch: {
    commodityData: {
      handler(value) {
        if (!this.cache.oaCommodityId) return
        this.handleWorkOrderData(value)
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('product/partner', ['addSku', 'editSku']),
    computeMax(index) {
      let number = 0
      this.editSkuData.settlementList.forEach(item => {
        number = number + Number(item.sectionStock)
      })

      if (number > Number(this.editSkuData.totalStock)) {
        this.$message.error('分段库存不能超过总库存')
        this.editSkuData.settlementList[index].sectionStock = 0
      }
    },
    showDelete(index, item) {
      if (item.id === '' || this.allowUpdateAll) {
        if (index !== 0) {
          return true
        }
        return false
      } else {
        if (index === 0) {
          return false
        } else {
          if (item.sectionStock != item.currentSectionStock) {
            return false
          }
          return true
        }
      }
    },
    handleWorkOrderData(value) {
      let { title, price, count, inventoryType, inventory } = value
      this.editSkuData.skuImageUrl = ''
      this.editSkuData.skuName = title
      this.editSkuData.salePrice = price.current
      this.editSkuData.originalPrice = price.origin
      this.editSkuData.hideStatus = value.hideStatus
      this.editSkuData.totalStock = count
      this.editSkuData.lowStockRation = 0
      this.editSkuData.baseVirtualSaleCount = 0
      this.editSkuData.switchRefundRatio = 0
      this.editSkuData.refundRatio = 0
      // 默认开启
      this.editSkuData.switchDistribution = 0
      this.editSkuData.settlementList = inventory.map(item => {
        return {
          sectionStock: item.count,
          id: 0,
          platformShareRatio: inventoryType === 'RATIO' ? item.value : 100,
          platformShareMoney: inventoryType === 'PRICE' ? item.value : 9999999999,
          settlementType: inventoryType === 'RATIO' ? 1 : 2,
          currentSectionStock: 0,
          specialDistributionRewardAmount: 0,
          switchSpecialDistribution: 0
        }
      })
    },
    handleSpecialDistributionRewardAmount(index) {
      if (this.editSkuData.settlementList[index].specialDistributionRewardAmount > this.editSkuData.salePrice) {
        this.editSkuData.settlementList[index].specialDistributionRewardAmount = 0
        this.$message.error('特殊分销金额不能高于售价')
      } else if (this.editSkuData.settlementList[index].specialDistributionRewardAmount < 0) {
        this.editSkuData.settlementList[index].specialDistributionRewardAmount = 0
        this.$message.error('特殊分销金额不能低于0')
      }
    },
    handleShareLimit(index) {
      if (
        this.editSkuData.settlementType === 1 &&
        this.editSkuData.settlementList[index].platformShareRatio > this.mallShareRatio &&
        this.editSkuData.settlementList[index].platformShareRatio != '100'
      ) {
        let obj = this.editSkuData.settlementList[index]
        obj.platformShareRatio = this.mallShareRatio
        this.editSkuData.settlementList.splice(index, 1, obj)
        console.log(this.editSkuData.settlementList)
        this.$message.error(`分成比例不能超过${this.mallShareRatio}%`)
      } else if (
        this.editSkuData.settlementType === 2 &&
        this.editSkuData.settlementList[index].platformShareMoney != this.editSkuData.salePrice &&
        (this.editSkuData.settlementList[index].platformShareMoney / this.editSkuData.salePrice).toFixed(4) >
          this.mallShareRatio / 100
      ) {
        this.editSkuData.settlementList[index].platformShareMoney = (
          this.editSkuData.salePrice *
          (this.mallShareRatio / 100)
        ).toFixed(2)
        this.$message.error(`分成比例不能超过${this.mallShareRatio}%`)
      }
    },

    validateSku() {
      //   submitForm(formName) {
      //   this.$refs[formName].validate((valid) => {
      //     if (valid) {
      //       alert('submit!');
      //     } else {
      //       console.log('error submit!!');
      //       return false;
      //     }
      //   });
      // },

      this.$refs.editSkuData.validate(valid => {
        if (!valid) {
          this.$message.error('表单信息不完整')
          return false
        }

        let tip = false
        let notAllPlatform = false
        if (this.editSkuData.settlementType === 1) {
          this.editSkuData.settlementList.forEach(item => {
            if (item.platformShareRatio != '100') {
              notAllPlatform = true
            }
            if (item.platformShareRatio == '0') {
              tip = true
            }
          })
        } else if (this.editSkuData.settlementType === 2) {
          this.editSkuData.settlementList.forEach(item => {
            if (item.platformShareMoney != this.editSkuData.salePrice) {
              notAllPlatform = true
            }
            if (item.platformShareMoney == 0) {
              tip = true
            }
          })
        }
        for (let i = 0; i < this.editSkuData.formItemList.length; i++) {
          console.log(this.editSkuData.formItemList[i])
          if (this.editSkuData.formItemList[i].label === '') {
            this.$message.error(`下单信息的第${i + 1}项信息名称不能为空`)
            return
          } else if (this.editSkuData.formItemList[i].code === '') {
            this.$message.error(`下单信息的第${i + 1}项信息类型不能为空`)
            return
          }
        }
        if (this.saleType === 3 && this.editSkuData.formItemList.length === 0) {
          this.$message.error('无码商品必须有下单信息')
          return
        }
        // if (!this.hasSubMerchant && notAllPlatform) {
        //   this.$message.error('选择的商家门店没有绑定二级商户号，只能选择我们平台全收')
        //   return
        // }
        console.log(this.editSkuData.settlementList)
        if (
          this.editSkuData.settlementList[this.editSkuData.settlementList.length - 1].sectionEnd >
          this.editSkuData.totalStock
        ) {
          this.$message.error('分段库存总和不能大于签约总库存')
          return
        }
        if (tip) {
          this.showTip = true
          return
        }
        this.submitEditSku()
      })
    },
    submitEditSku() {
      this.showTip = false
      this.editSkuData.settlementList.map(i => {
        i.settlementType = this.editSkuData.settlementType
        return i
      })
      if (!this.editSkuData.productSkuId && !this.isEdit) {
        this.addSku(this.editSkuData)
      } else {
        this.editSku(this.editSkuData)
      }
      this.$emit('input', false)
    },

    handleDeleteOptionsItem(n) {
      this.editSkuData.options.splice(n, 1)
    },
    handleAddOptionsItem() {
      this.editSkuData.options.push({
        name: ''
      })
    },
    handleAdd() {
      console.log(this.isAdd)
      if (!this.cache.oaCommodityId || !this.isAdd) {
        this.editSkuData = {
          productSkuId: '',
          skuImageUrl: '',
          skuName: '',
          options: [],
          buyLimit: 0,
          totalStock: 0,
          salePrice: 0,
          originalPrice: 0,
          settlementType: 1,
          lowStockRation: 0,
          id: '',
          switchRefundRatio: 0,
          hideStatus: 0,
          refundRatio: 0,
          baseVirtualSaleCount: 0,
          extraInfoNum: 0,
          formItemList: [],
          switchDistribution: 0,
          lowStockRation: 0,
          settlementList: [
            {
              sectionStock: 0,
              id: '',
              platformShareMoney: 9999999999,
              platformShareRatio: 100,
              settlementType: 1,
              currentSectionStock: 0,
              specialDistributionRewardAmount: 0,
              switchSpecialDistribution: 0
            }
          ]
        }
      } else {
        this.handleWorkOrderData(this.commodityData)
      }

      if (this.saleType === 3) {
        this.editSkuData.formItemList.push({
          code: '',
          required: false,
          label: ''
        })
      }

      this.isEdit = false
      this.$emit('input', true)
    },
    handleEdit(val) {
      this.editSkuData = { ...this.editSkuData, ...val }
      this.isEdit = true
      this.$emit('input', true)
    },
    beforeClose() {
      this.$emit('input', false)
    },
    handleAddFormItem() {
      this.editSkuData.formItemList.push({
        code: '',
        required: false,
        label: ''
      })
    },
    handleDeleteFormItem(n) {
      this.editSkuData.formItemList.splice(n, 1)
    },
    handleAddSettlement() {
      this.editSkuData.settlementList.push({
        sectionStock: 0,
        platformShareMoney: 9999999999,
        id: '',
        platformShareRatio: 100,
        settlementType: this.editSkuData.settlementType,
        currentSectionStock: 0,
        specialDistributionRewardAmount: 0,
        switchSpecialDistribution: 0
      })
    },
    handleDeleteSettlement(n) {
      this.editSkuData.settlementList.splice(n, 1)
    }
  }
}
</script>

<style lang="less" scoped>
.componentContainer {
  width: 100%;
}

.editSkuContainer {
  .el-input-number--small,
  .el-input {
    width: 200px;
  }

  .skuSettlement {
    .border;
    .radius;
    background: var(--background);
    padding: 10px;
    margin-bottom: 10px;
    position: relative;
    .deleteBtn {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }
}
</style>
