<template>
  <div class="pickPartnerProductContainer">
    <el-dialog
      :visible="value"
      :close-on-click-modal="false"
      :before-close="beforeClose"
      title="添加外接商品款式"
      width="800px"
    >
      <el-form label-width="10em" class="editSkuContainer">
        <el-form-item label="核销码池">
          <div class="checkCodeInfo">
            <template v-if="editSkuData.checkCodePoolId">
              <div class="image" v-bg.container="editSkuData.skuImageUrl" style="width: 50px; height: 50px"></div>
              <div class="name">{{ editSkuData.skuName }}</div>
            </template>
            <el-button
              :disabled="!allowUpdateAll && editSkuData.id !== ''"
              @click="isShowCheckCodePicker = true"
              type="primary"
              plain
            >
              <span v-if="editSkuData.checkCodePoolId">更换</span>
              <span v-else>选择</span>
            </el-button>
          </div>
        </el-form-item>

        <template v-if="editSkuData.checkCodePoolId">
          <el-form-item label="录用量">
            <el-input-number
              :min="0"
              :max="maxCount"
              v-model="editSkuData.totalStock"
              controls-position="right"
            ></el-input-number>
            <bm-tip>总的可用量：{{ maxCount }}</bm-tip>
          </el-form-item>
        </template>
        <el-form-item label="库存预警比例">
          <el-input-number
            :min="0"
            :max="100"
            v-model="editSkuData.lowStockRation"
            placeholder="请输入库存预警比例"
            controls-position="right"
          ></el-input-number>
          <span style="margin-left: 5px">%</span>
        </el-form-item>
        <el-form-item label="是否隐藏sku">
          <el-radio-group v-model="editSkuData.hideStatus">
            <el-radio :disabled="disableHideSku" :label="0">显示</el-radio>
            <el-radio :disabled="disableHideSku" :label="1">隐藏</el-radio>
          </el-radio-group>

          <bm-tip>第一个Sku不能隐藏，只能显示</bm-tip>
        </el-form-item>
        <el-form-item label="基础销量数" prop="baseVirtualSaleCount">
          <el-input-number
            v-model="editSkuData.baseVirtualSaleCount"
            controls-position="right"
            :min="0"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="款式限购" prop="buyLimit">
          <el-input-number v-model="editSkuData.buyLimit" controls-position="right" :min="0"></el-input-number>
        </el-form-item>
        <el-form-item label="附带信息">
          <div v-for="(item, index) in editSkuData.formItemList" :key="'formItem' + index">
            <el-input
              :disabled="!allowUpdateAll && editSkuData.id !== ''"
              v-model="item.label"
              style="margin-right: 10px"
              placeholder="信息名称"
            ></el-input>
            <el-select :disabled="!allowUpdateAll && editSkuData.id !== ''" v-model="item.code" placeholder="请选择">
              <el-option v-for="i in options" :key="i.value" :label="i.label" :value="i.value"> </el-option>
            </el-select>

            <el-checkbox
              :disabled="!allowUpdateAll && editSkuData.id !== ''"
              v-model="item.required"
              style="margin: 10px"
              >必填</el-checkbox
            >
            <el-button
              class="deleteBtn"
              type="danger"
              :disabled="!allowUpdateAll && editSkuData.id !== ''"
              @click="handleDeleteFormItem(index)"
              icon="el-icon-delete"
            ></el-button>
          </div>

          <el-button
            :disabled="!allowUpdateAll && editSkuData.id !== ''"
            style="margin-top: 10px"
            @click="handleAddFormItem"
            type="success"
            >新增下单信息</el-button
          >
        </el-form-item>

        <el-form-item label="每份需填" v-if="editSkuData.formItemList.length !== 0">
          <el-input-number
            :disabled="!allowUpdateAll && editSkuData.id !== ''"
            v-model="editSkuData.extraInfoNum"
            controls-position="right"
            :min="0"
            :max="99"
          ></el-input-number>

          <bm-tip>每购买一份，需填写信息的份数</bm-tip>
        </el-form-item>

        <el-form-item label="结算方式">
          <el-radio-group v-model="editSkuData.settlementType">
            <el-radio :label="1" :disabled="!allowUpdateAll && editSkuData.id !== ''">按比例结算</el-radio>
            <el-radio :label="2" :disabled="!allowUpdateAll && editSkuData.id !== ''">固定金额</el-radio>
          </el-radio-group>
          <div class="skuSettlement" v-for="(i, n) in editSkuData.settlementList" :key="n">
            <el-form>
              <el-form-item>
                <div style="width: 50%; display: inline-block; vertical-align: top">
                  <span style="margin: 10px">第{{ n + 1 }}段库存</span>
                  <el-input
                    class="skuSettlementInput"
                    style="width: 150px"
                    v-model="i.sectionStock"
                    type="number"
                    @blur="computeMax(n)"
                  ></el-input>
                  <div v-if="i.id !== ''" style="margin: 10px; color: red">剩余库存量 {{ i.currentSectionStock }}</div>
                </div>
                <div style="width: 50%; display: inline-block">
                  <span style="margin: 10px">平台分成</span>
                  <template v-if="editSkuData.settlementType === 1">
                    <el-input
                      type="number"
                      style="width: 150px"
                      :disabled="!allowUpdateAll && i.id !== ''"
                      v-model="i.platformShareRatio"
                      @blur="handleShareLimit(n)"
                      :min="0"
                      :max="100"
                    >
                    </el-input>

                    <span style="margin-left: 5px">%</span>
                  </template>
                  <template v-else-if="editSkuData.settlementType === 2">
                    <el-input
                      type="number"
                      style="width: 150px"
                      :disabled="!allowUpdateAll && i.id !== ''"
                      v-model="i.platformShareMoney"
                      @blur="handleShareLimit(n)"
                      :max="editSkuData.salePrice"
                      :min="0"
                    >
                    </el-input>
                  </template>

                  <div>
                    <span style="margin: 10px">商家分成 </span>
                    <template v-if="editSkuData.settlementType === 1">
                      <span>{{ 100 - i.platformShareRatio }}</span>
                      <span>%</span>

                      <div v-if="i.platformShareRatio == 0" style="color: red; font-size: 20px; margin-left: 10px">
                        请确定是商家百分百全收
                      </div>
                      <div v-if="i.platformShareRatio >= 30" style="color: red; font-size: 20px; margin-left: 10px">
                        请确定商品业务类型，判断是否需要填写特殊分销金
                      </div>
                    </template>
                    <template v-else-if="editSkuData.settlementType === 2">
                      <span>{{ (skuPrice - i.platformShareMoney).toFixed(2) }}</span>
                      <span>元</span>
                      <div v-if="i.platformShareMoney == 0" style="color: red; font-size: 20px; margin-left: 10px">
                        请确定是商家百分百全收
                      </div>
                      <div
                        v-if="i.platformShareMoney / editSkuData.salePrice >= 0.3"
                        style="color: red; font-size: 20px; margin-left: 10px"
                      >
                        请确定商品业务类型，判断是否需要填写特殊分销金
                      </div>
                    </template>
                  </div>
                </div>
              </el-form-item>
              <el-form-item>
                <span style="margin: 10px">特殊奖励金开关 </span>
                <el-switch v-model="i.switchSpecialDistribution" :inactive-value="0" :active-value="1"></el-switch>
                <span v-if="i.switchSpecialDistribution === 1" style="margin: 10px">特殊分销奖励金 </span>

                <el-input
                  v-if="i.switchSpecialDistribution === 1"
                  @change="handleSpecialDistributionRewardAmount(n)"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                  v-model="i.specialDistributionRewardAmount"
                >
                  <template slot="append">元</template></el-input
                >
              </el-form-item>
            </el-form>
            <template v-if="n !== 0">
              <el-button
                class="deleteBtn"
                type="danger"
                v-if="i.id === ''"
                @click="handleDeleteSettlement(n)"
                icon="el-icon-delete"
              ></el-button>
            </template>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button v-if="canAddSettlement" @click="handleAddSettlement" type="success">增加分成比例</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="validateSku" :disabled="!isReadySubmitEditSku" type="primary">完成</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog :visible.sync="showTip" :close-on-click-modal="false" title="提醒" center width="700px">
      <h3 style="text-align: center">请确定商家百分百全收</h3>
      <span slot="footer" class="dialog-footer">
        <el-button round @click="showTip = false">取 消</el-button>
        <el-button type="primary" round @click="submitEditSku">确 定</el-button>
      </span>
    </el-dialog>
    <el-drawer
      title="挑选核销码池"
      :visible.sync="isShowCheckCodePicker"
      :close-on-press-escape="false"
      :wrapperClosable="false"
    >
      <div class="checkCodeContainer">
        <div class="checkCodeList">
          <template v-if="checkCodeList && checkCodeList.length">
            <div v-for="(i, n) in checkCodeList" :key="n" @click="handlePickCheckCode(i)" class="item">
              <div class="image" v-bg.container="i.imageUrl"></div>
              <div class="name">{{ i.name }}</div>
              <div class="infoContainer">
                <div class="price">
                  <span class="salePrice">￥{{ i.salePrice | formatPrice }}</span>
                  <span class="originalPrice"> / 原价：{{ i.originalPrice | formatPrice }}</span>
                </div>
                <div class="count">
                  <span class="residueCount">库存：{{ i.residueCount }}</span>
                  <span class="totalCount"> / 总量：{{ i.totalCount }}</span>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="nullInfo">
              <div class="text">可用核销码池列表为空</div>
              <el-button type="primary" @click="$router.push('/product/partnerProduct/checkCode/list')"
                >导入新核销码</el-button
              >
            </div>
          </template>
        </div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="checkCodePage.total"
          :page-size="10"
          :current-page="checkCodePage.page"
          @current-change="onCheckCodePageChange"
        ></el-pagination>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    options: [
      {
        value: 'idCard',
        label: '身份证号码'
      },
      {
        value: 'phone',
        label: '手机号码'
      },
      {
        value: 'time',
        label: '时间格式'
      },
      {
        value: 'date',
        label: '日期格式'
      },
      {
        value: 'text',
        label: '文本格式'
      }
    ],
    editSkuData: {
      productSkuId: '',
      checkCodePoolId: '',
      skuImageUrl: '',
      skuName: '',
      totalStock: 0,
      salePrice: 0,
      baseVirtualSaleCount: 0,
      extraInfoNum: 0,
      buyLimit: 0,
      formItemList: [
        {
          code: '',
          required: false,
          label: ''
        }
      ],
      originalPrice: 0,
      settlementType: 1,
      hideStatus: 0,
      lowStockRation: 0,
      settlementList: [
        {
          sectionStock: 0,
          id: '',
          platformShareMoney: 9999999999,
          platformShareRatio: 100,
          settlementType: 1,
          currentSectionStock: 0,
          specialDistributionRewardAmount: 0,
          switchSpecialDistribution: 0
        }
      ]
    },
    currentCheckCodeInfo: {
      id: '',
      name: '',
      imageUrl: '',
      checkCodeType: 1,
      lastImportStatus: 1,
      lastWriteOffStatus: 0,
      originalPrice: 10,
      hideStatus: 0,
      residueCount: '',
      salePrice: 0,
      totalCount: '',
      checkCodePoolStateVO: {
        checkCount: '0',
        latchCount: '0',
        refundCount: 0,
        sellCount: '0',
        totalCount: '1000'
      }
    },
    isShowCheckCodePicker: false,
    checkCodePage: {
      total: 0,
      page: 1
    },
    showTip: false,
    checkCodeList: [],
    isEdit: false
  }),
  computed: {
    ...mapGetters('product/partner', ['businessInfo', 'hasSubMerchant', 'allowUpdateAll']),
    ...mapGetters({
      mallShareRatio: 'product/basic/mallShareRatio'
    }),
    isReadySubmitEditSku() {
      if (!this.editSkuData.checkCodePoolId || !this.editSkuData.totalStock) {
        return false
      } else {
        return true
      }
    },
    maxCount() {
      if (this.currentCheckCodeInfo && this.currentCheckCodeInfo.id) {
        console.log(this.editSkuData)
        if (this.editSkuData.id !== '') {
          return Number(this.currentCheckCodeInfo.residueCount) + this.editSkuData.totalStock
        } else {
          return Number(this.currentCheckCodeInfo.residueCount)
        }
      } else {
        return Infinity
      }
    },
    skuPrice() {
      if (this.currentCheckCodeInfo && this.currentCheckCodeInfo.id) {
        return Number(this.currentCheckCodeInfo.salePrice)
      } else {
        return this.editSkuData.salePrice
      }
    },
    canAddSettlement() {
      const l = this.editSkuData.settlementList.length
      if (this.editSkuData.settlementList[l - 1].sectionEnd >= this.editSkuData.totalStock) {
        return false
      } else {
        return true
      }
    }
  },
  watch: {
    value() {
      this.getCheckCodeList()
    },
    'editSkuData.checkCodePoolId'(val) {
      this.getSkuInfo(val)
    }
  },
  methods: {
    ...mapMutations('product/partner', ['addSku', 'editSku']),
    computeMax(index) {
      let number = 0
      this.editSkuData.settlementList.forEach(item => {
        number = number + Number(item.sectionStock)
      })

      if (number > Number(this.editSkuData.totalStock)) {
        this.$message.error('分段库存不能超过总库存')
        this.editSkuData.settlementList[index].sectionStock = 0
      }
    },
    handleSpecialDistributionRewardAmount(index) {
      if (this.editSkuData.settlementList[index].specialDistributionRewardAmount > this.editSkuData.salePrice) {
        this.editSkuData.settlementList[index].specialDistributionRewardAmount = 0
        this.$message.error('特殊分销金额不能高于售价')
      } else if (this.editSkuData.settlementList[index].specialDistributionRewardAmount < 0) {
        this.editSkuData.settlementList[index].specialDistributionRewardAmount = 0
        this.$message.error('特殊分销金额不能低于0')
      }
    },
    getSkuInfo(id) {
      this.$http.get(`/boom-center-product-service/sysAdmin/checkCodePool/${id}`).then(res => {
        this.currentCheckCodeInfo = res
      })
    },

    getCheckCodeList() {
      return this.$http
        .get('/boom-center-product-service/sysAdmin/checkCodePool', {
          params: { ...this.checkCodePage, filterBindable: 1, businessId: this.businessInfo.id || '' }
        })
        .then(res => {
          this.checkCodeList = res.list
          this.checkCodePage.total = res.total
          return
        })
    },
    onCheckCodePageChange(val) {
      this.checkCodePage.page = val
      this.getCheckCodeList()
    },
    handlePickCheckCode(val) {
      this.currentCheckCodeInfo = { ...val }
      this.editSkuData.checkCodePoolId = val.id
      this.editSkuData.skuName = val.name
      this.editSkuData.skuImageUrl = val.imageUrl
      this.editSkuData.salePrice = val.salePrice
      this.editSkuData.hideStatus = val.hideStatus
      this.editSkuData.originalPrice = val.originalPrice
      this.isShowCheckCodePicker = false
    },
    handleShareLimit(index) {
      if (
        this.editSkuData.settlementType === 1 &&
        this.editSkuData.settlementList[index].platformShareRatio > this.mallShareRatio &&
        this.editSkuData.settlementList[index].platformShareRatio != '100'
      ) {
        this.editSkuData.settlementList[index].platformShareRatio = this.mallShareRatio
        this.$message.error(`分成比例不能超过${this.mallShareRatio}%`)
      } else if (
        this.editSkuData.settlementType === 2 &&
        this.editSkuData.settlementList[index].platformShareMoney != this.editSkuData.salePrice &&
        (this.editSkuData.settlementList[index].platformShareMoney / this.editSkuData.salePrice).toFixed(4) >
          this.mallShareRatio / 100
      ) {
        this.editSkuData.settlementList[index].platformShareMoney = (
          this.editSkuData.salePrice *
          (this.mallShareRatio / 100)
        ).toFixed(2)
        this.$message.error(`分成比例不能超过${this.mallShareRatio}%`)
      }
    },
    validateSku() {
      let tip = false
      let notAllPlatform = false
      if (this.editSkuData.settlementType === 1) {
        this.editSkuData.settlementList.forEach(item => {
          if (item.platformShareRatio != '100') {
            notAllPlatform = true
          }
          if (item.platformShareRatio == '0') {
            tip = true
          }
        })
      } else if (this.editSkuData.settlementType === 2) {
        this.editSkuData.settlementList.forEach(item => {
          if (item.platformShareMoney != this.editSkuData.salePrice) {
            notAllPlatform = true
          }
          if (item.platformShareMoney == 0) {
            tip = true
          }
        })
      }
      for (let i = 0; i < this.editSkuData.formItemList.length; i++) {
        console.log(this.editSkuData.formItemList[i])
        if (this.editSkuData.formItemList[i].label === '') {
          this.$message.error(`下单信息的第${i + 1}项信息名称不能为空`)
          return
        } else if (this.editSkuData.formItemList[i].code === '') {
          this.$message.error(`下单信息的第${i + 1}项信息类型不能为空`)
          return
        }
      }
      // if (!this.hasSubMerchant && notAllPlatform) {
      //   this.$message.error('选择的商家门店没有绑定二级商户号，只能选择我们平台全收')
      //   return
      // }
      if (
        this.editSkuData.settlementList[this.editSkuData.settlementList.length - 1].sectionEnd >
        this.editSkuData.totalStock
      ) {
        this.$message.error('分段库存总和不能大于签约总库存')
        return
      }
      if (tip) {
        this.showTip = true
        return
      }
      this.submitEditSku()
    },
    submitEditSku() {
      this.showTip = false
      this.editSkuData.settlementList.map(i => {
        i.settlementType = this.editSkuData.settlementType
        return i
      })
      // this.editSkuData.checkCodePoolId = this.editSkuData.id
      if (!this.editSkuData.productSkuId && !this.isEdit) {
        this.addSku(this.editSkuData)
      } else {
        this.editSku(this.editSkuData)
      }
      this.$emit('input', false)
    },
    handleAdd() {
      this.editSkuData = {
        productSkuId: '',
        checkCodePoolId: '',
        skuImageUrl: '',
        buyLimit: 0,
        skuName: '',
        id: '',
        totalStock: 0,
        salePrice: 0,
        originalPrice: 0,
        settlementType: 1,
        baseVirtualSaleCount: 0,
        hideStatus: 0,
        lowStockRation: 0,
        switchDistribution: 1,
        extraInfoNum: 0,
        formItemList: [],
        switchRefundRatio: 0,
        settlementList: [
          {
            sectionStock: 0,
            id: '',
            platformShareMoney: 9999999999,
            platformShareRatio: 100,
            settlementType: 1,
            currentSectionStock: 0,
            specialDistributionRewardAmount: 0,
            switchSpecialDistribution: 0
          }
        ]
      }
      this.isEdit = false
      this.$emit('input', true)
    },
    handleEdit(val) {
      console.log(val)
      // this.editSkuData.id = val.checkCodePoolId
      this.editSkuData = { ...this.editSkuData, ...val }
      this.isEdit = true
      this.$emit('input', true)
    },
    beforeClose() {
      this.$emit('input', false)
    },
    handleAddSettlement() {
      let start = parseInt(this.editSkuData.settlementList[this.editSkuData.settlementList.length - 1].sectionEnd) + 1
      this.editSkuData.settlementList.push({
        sectionStock: 0,
        platformShareMoney: 9999999999,
        id: '',
        platformShareRatio: 100,
        settlementType: this.editSkuData.settlementType,
        currentSectionStock: 0,
        specialDistributionRewardAmount: 0,
        switchSpecialDistribution: 0
      })
    },
    handleDeleteSettlement(n) {
      this.editSkuData.settlementList.splice(n, 1)
    },
    handleAddFormItem() {
      console.log(this.editSkuData)
      this.editSkuData.formItemList.push({
        code: '',
        required: false,
        label: ''
      })
    },
    handleDeleteFormItem(n) {
      this.editSkuData.formItemList.splice(n, 1)
    }
  },
  created() {},
  mounted() {}
}
</script>

<style lang="less" scoped>
.componentContainer {
  width: 100%;
}

.editSkuContainer {
  .el-input-number--small,
  .el-input {
    width: 200px;
  }

  .checkCodeInfo {
    display: flex;
    align-items: center;
    .image {
      .square(50px);
    }
    .name {
      margin: 0 10px;
    }
  }
  .skuSettlement {
    .border;
    .radius;
    background: var(--background);
    padding: 10px;
    margin-bottom: 10px;
    position: relative;
    .deleteBtn {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }
}
.checkCodeContainer {
  height: 100vh;
  text-align: center;
  .checkCodeList {
    height: calc(~'100vh - 150px');
    display: flex;
    flex-direction: column;
    // justify-content: space-around;
    overflow-y: auto;
    .item {
      .pointer;
      display: flex;
      align-items: center;
      padding: 0 10px 10px 10px;
      &:hover {
        background: var(--background);
      }
      .image {
        .square(60px);
      }
      .name {
        font-weight: bold;
        flex-grow: 1;
        text-align: left;
        margin: 0 10px;
      }
      .infoContainer {
        text-align: right;
        .price {
          .salePrice {
            font-size: 18px;
            color: var(--primary);
          }
        }
        .originalPrice,
        .totalCount {
          font-size: 12px;
          color: var(--text-grey);
        }
      }
    }
    .nullInfo {
      .text {
        margin: 20px 0;
      }
    }
  }
}
</style>
